@import "@/styles/base";

.chip {
    display: inline-flex;
    gap: spacing(1);
    align-items: center;

}

.chip_icon {
    cursor: pointer;
    stroke: theme(neutral, 50);
     & > path {
        stroke: theme(neutral, 50);
     }
}
.chip_text {
    color: var(--text-color);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 140% */
}

.variant_transparent {
    background-color: transparent;
    --text-color: #{theme(neutral, 50)};
}