@import '@/styles/base';

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.animate-shimmer {
  overflow: hidden;
  contain: paint;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(theme(neutral, 12), 0) 0,
      rgba(theme(neutral, 12), 0.2) 20%,
      rgba(theme(neutral, 12), 0.5) 60%,
      rgba(theme(neutral, 12), 0)
    );
    animation-name: shimmer;
    animation-duration: var(--animate-shimmer-duration, 1s);
    animation-iteration-count: var(--animate-shimmer-iteration-count,infinite);
    content: '';
  }
}
