@import "@/styles/base";

.events_list_container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(300px, 1fr));
  grid-auto-rows: auto;
  gap: spacing(6);
  align-content: flex-start;
  padding: spacing(6);
  @include large-desktop-up {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}
