@import "@/styles/base";

.tabs {
  --accent: var(--community-color, #{theme(brand, main)});
  --tab-border-color: #{theme(neutral, 16)};

  display: flex;
  gap: spacing(6);
  width: 100%;
  position: relative;
  
  &::after {
    position: absolute;
    content: '';
    inset: 0;
    width: 100%;
    border-bottom: 1px solid var(--tab-border-color);
    pointer-events: none;
  }

  @include mobile-only {
    overflow-x: auto;
    gap: spacing(2);
  }
}

.tabs_alignment_center {
  justify-content: center;

  @include mobile-only {
    & .tab {
      flex-grow: 0;
      padding-inline: spacing(2);
    }
  }
}

.tabs_container {
  display: flex;
  gap: spacing(6);
  flex-direction: column;
}

.tab {
    position: relative;
    user-select: none;
    cursor: pointer;
    font-family: var(--font-lexend);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    padding: spacing(2);

    @include mobile-only {
      flex-grow: 1;
      padding: 1px;
      margin-bottom: 1px;
    }
}

.active {
    color: theme(text-color, main);
    z-index: 2;
}

.inactive {
    color: theme(neutral, 50);
    border-width: 0;
}

.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--accent);
}
.tabs_container {
  width: 100%;
}

.link_tab {
  position: relative;
  user-select: none;
  cursor: pointer;
  font-family: var(--font-lexend);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  padding: spacing(2);
  color: theme(text-color, main);
  text-decoration: none;
  bottom: -2px;
  @include mobile-only {
    width: 100%;
    display: block;
    text-align: center;
  }
}