@import "@/styles/base";

.container {
    display: flex;
    flex-direction: column;
    gap: spacing(8);
    padding: spacing(8);
    @include mobile-only {
        gap: spacing(4);
        padding: spacing(4);
    }
}

.heading {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
    font-family: var(--font-lexend);
}

.body {
    color: theme(neutral, 50);
    font-family: var(--font-lexend);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 142.857% */
}