@import "@/styles/base";

.tabs_section {
  grid-area: inventory-toolbar;
  display: flex;
  width: 100%;

  @include mobile-only {
    background-color: theme(background, component);
    border-top-left-radius: radius(2);
    border-top-right-radius: radius(2);
    z-index: 2;
    position: fixed;
    bottom: 0;
    padding: spacing(4) spacing(4) spacing(6);
  }
}
.community_inventory {
  --avatar-width: min(25rem, 25vw);
  display: grid;
  column-gap: spacing(2);
  row-gap: spacing(6);
  grid-template-columns: [avatar-start] var(--avatar-width) [avatar-end inventory-start inventory-toolbar-start] 1fr [inventory-end inventory-toolbar-end];
  grid-template-rows: [avatar-start inventory-toolbar-start] auto [inventory-toolbar-end inventory-start] 1fr [avatar-end inventory-end];
  height: 100%;

  @include mobile-only {
    grid-template-columns: [avatar-start inventory-start inventory-toolbar-start]1fr [avatar-end inventory-end inventory-toolbar-end];
    grid-template-rows: [avatar-start inventory-start] 1fr [avatar-end inventory-end];
    padding: 0;
  }

  &-items {
    --item-gap: #{spacing(4)};
    display: flex;
    flex-direction: column;
    gap: var(--item-gap);
    grid-area: inventory;

    &__items-container {
      display: flex;
      gap: var(--item-gap);
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      flex-wrap: wrap;

      & > div {
        flex-basis: 100%;
        @media (min-width: $md-start) and (max-width: $md-max) {
          flex-basis: calc(50% - var(--item-gap));
        }
        @media (min-width: $lg-start) and (max-width: $lg-max) {
          flex-basis: calc(33.333% - var(--item-gap));
        }
        @media (min-width: $xl-start) {
          flex-basis: calc(25% - var(--item-gap));
        }
      }
    }
  }
}
