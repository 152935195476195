@import "@/styles/base";

.hide_on_mobile {
  @include mobile-only {
    display: none;
  }
}
.avatar_preview_container {
  align-self: stretch;
  position: relative;
  z-index: 0;
  padding: 0 spacing(2) 0 spacing(4);
  grid-area: avatar;

  @include mobile-only {
    padding: 0;
  }

  .avatar-image-wrapper {
    position: sticky;
    top: 10vh;
    display: flex;
    flex-direction: column;
    gap: spacing(1);

    @include mobile-only {
      position: static; 
      max-width: 100vw;
      overflow: clip;
    }
  }

  .container {
    position: relative;
  }

  .images-container {
    position: relative;
    width: 100%;
    isolation: isolate;
    aspect-ratio: 2/3.5;
  }

  .avatar-image {
    pointer-events: none;
    width: 100%;
  }

  .avatar-loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    animation: blink_avatar_skeleton 2s linear infinite;
    object-fit: contain;
    transform: scale(0.8) translateY(-5%);
  }

  &.loaded {
    .avatar-image {
      opacity: 1;
      display: block;
    }
  }

  &.loading {
    .avatar-image {
      opacity: 0;
      display: none;
    }
  }

  .avatar_options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: spacing(3);

    @include mobile-only {
      position: absolute;
      top: 0;
      right: spacing(2);
    }
  }

  .btn-transparent {
    font-weight: bold;
    backdrop-filter: blur(16px);
    background: rgba(255, 255, 255, 0.01);

    &:hover {
      background-color: rgba(255, 255, 255, 0.03);
    }

    &:last-child {
      margin-right: 0;
    }

    .stroke {
      stroke: #fff;
    }

    .fill {
      fill: #fff;
    }
  }

  .hide-avatar {
    .stroke {
      margin-right: spacing(2);
    }
  }

  .platform-image {
    object-fit: contain;
    object-position: center bottom;
    z-index: -1;
    top: auto !important;
    transform: scale(1.5) translateY(-10%);
    pointer-events: none;
  }
}

.download-avatar {
  padding: spacing(2);
}


@keyframes blink_avatar_skeleton {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.5;
  }
}

.download_avatar_icon {
  @include mobile-only {
    display: none !important;
  }
}

.inventory_slot {
  background-color: theme(neutral, 16);
  border:  1px solid theme(neutral, 25);
  border-radius: radius(2);
  text-decoration: none;
  color: theme(text-color, main);
  display: none;
  padding: spacing(2);
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  aspect-ratio: 1/1;
  width: 72px;
  font-size: 12px;
  font-family: var(--font-integral-cf);

  &:hover {
    background-color: theme(neutral, 8);
  }

  @include mobile-only {
    display: flex;
  }
}