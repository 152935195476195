@import "@/styles/base";

.event_status {
  position: relative;
  display: flex;
  align-items: center;
}

.status {
  color: theme(neutral, 50);
  font-weight: 700;
  font-size: 12px;
}

.status.live {
  color: theme(status, success);
}