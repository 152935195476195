@import "@/styles/base";

.mobile_filter_container {
    padding: spacing(3) spacing(4);
    background: theme(background, component);
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    @include desktop-up {
        display: none;
    }
}
.filterBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparentize(theme(neutral, 100), 0.96);
    padding: 12px;
    border-radius: 8px;
}
.filter_label {
    font-size: 12px;
    font-weight: 500;
}
.filtersCount {
    display: flex;
    color: theme(neutral, 50);
    gap: spacing(2);
    font-size: 12px;
    align-items: center;
}
.active_filters {
    display: flex;
    gap: spacing(3);
    flex-wrap: wrap;
}
.mobile_filter_options_container {
    display: flex;
    flex-direction: column;
    gap: spacing(6);
    padding: spacing(6);
    width: 100vw;
    height: 90vh;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: theme(background, app);
    border-top-left-radius: radius(2);
    border-top-right-radius: radius(2);

    @include desktop-up {
        display: none;
    }
}
.filter_header {
    display: flex;
    flex-direction: column;
    gap: spacing(6);
    align-items: center;
}
.close {
    width: 120px;
    height: 4px;
    background: theme(neutral, 16);
    border-radius: 4px;
}
.apply_button {
    background: var(--community-color, #{theme(brand, main)});
}
.mobile_options {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: spacing(2);
    overflow-y: scroll;
    :global {
        .single-tag {
            padding: spacing(3);
            font-weight: 500;
            color: transparentize(theme(neutral, 100), 0.75);
        }
        .single-tag.active {
            color: theme(neutral, 100);
            &:before {
                opacity: 0;
            }
        }
    }
}
.desktop_options {
    display: none;
    @include desktop-up {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: spacing(2);
    }
    :global {
        .react-loading-skeleton {
            width: 100px;
        }
        .single-tag {
            padding: spacing(2) spacing(3);
            color: transparentize(theme(neutral, 100), 0.75);
            font-weight: 500;
        }
        .single-tag.active {
            color: theme(neutral, 100);
            &:before {
                opacity: 0;
            }
        }
    }
}