@import "@/styles/base";

.price_resolution {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: spacing(2) spacing(3);
    gap: spacing(2);
    border-radius: spacing(3);
    border: 1px solid transparentize(theme(neutral, 100), 0.92);
}
.winnable {
    background: theme(status, success);
    color: theme(neutral, 2);
}