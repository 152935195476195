@import "@/styles/base";

.prizepool_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px,1fr));
    grid-auto-rows: auto;
    gap: spacing(2);
    align-content: flex-start;
    row-gap: spacing(4);

    @include mobile-only {
        grid-template-columns: minmax(170px,1fr);
    }

    --winnable-gradient: linear-gradient(0deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02)), radial-gradient(81.25% 81.25% at 21.81% 18.75%, rgba(68, 255, 87, 0.1) 0%, rgba(68, 255, 87, 0) 100%)
}

.prize_container {
    display: flex;
    flex-direction: column;
    gap: spacing(4);

    @include mobile-only {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: transparentize(theme(neutral, 100), 0.96);
        padding: spacing(3);
        border-radius: radius(2);
    }
}

.prize {
    display: flex;
    flex-direction: column;
    gap: spacing(2);
    align-items: center;
    justify-content: center;
    background: transparentize(theme(neutral, 100), 0.96);
    padding: spacing(3);

    @include mobile-only {
        padding: 0;
        background: transparent;
    }
}
.exp {
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    align-items: center;
    justify-content: center;
    font-weight: 700;

    @include mobile-only {
        flex-direction: row;
        
        img, svg {
            width: 40px;
            height: 40px;
        }
    }
}
.prize_container.winnable {
    @include mobile-only {
        background: var(--winnable-gradient);
    }
}
.prize.winnable {
    background: var(--winnable-gradient);

    @include mobile-only {
        background: unset;
    }

}