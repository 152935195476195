@import "@/styles/base";

.brand {
  --bar-color: #{theme(brand, main)};
}
.success {
  --bar-color: #{theme(status, success)};
}
.community {
  --bar-color: var(--community-color, #{theme(brand, main)});
}
.community_gradient {
  --bar-color: linear-gradient(to right, transparent, var(--community-color, #{theme(brand, main)}));
}

.progressbar {
  --stripe1-color: #{theme(neutral, 8)};
  --stripe2-color: transparent;
  --stripe-size: 1rem;
  height: 2rem;
  position: relative;
  border-radius: radius(50);
  isolation: isolate;
}

.progressbar.small {
  height: 1rem;
}

.progressbar.extra_small {
  height: 0.5rem;
}

.variant_dashed {
  background-image: linear-gradient(
    135deg,
    var(--stripe2-color) 25%,
    var(--stripe1-color) 25%,
    var(--stripe1-color) 50%,
    var(--stripe2-color) 50%,
    var(--stripe2-color) 75%,
    var(--stripe1-color) 75%,
    var(--stripe1-color) 100%
  );
  background-size: var(--stripe-size) var(--stripe-size);
  
}

.variant_solid {
  background-color: theme(neutral, 16);
  background-size: var(--stripe-size) var(--stripe-size);
  height: 1px;
  width: 100%;
}

.bar {
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  background: var(--bar-color);
  border-radius: radius(50);
  height: 100%;
  width: var(--progress);
  overflow: clip;
  max-width: 100%;
  z-index: 1;
  border: 1px solid var(--bar-color);
}

.no_border {
  border: 0;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  font-weight: 700;
}

.label.small {
  font-size: 12px;
}

.label.extra_small {
  font-size: 8px;
}

.label_hidden {
  display: none;
}
