@import '@/styles/base';

.list_error {
  flex: 1;
  overflow: hidden;
  grid-column: 1/-1;
  gap: spacing(6);
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200%;
  height: 200%;
  z-index: -1;
}

.text {
  font-weight: 700;
}

.container {
  position: relative;
}

.error_image {
  width: 100%;
  @include mobile-only {
    
    height: auto;
  }
}