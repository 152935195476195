@import "@/styles/base";

.event_overview {
    display: grid;
    grid-template-columns: 1fr;
    
    gap: spacing(6);
    align-items: start;

    @include large-desktop-up {
        grid-template-columns: 350px 1fr;
    }
}

.event_body {
    display: flex;
    flex-direction: column;
    gap: spacing(6);
}

.progress {
    padding: spacing(8);
    gap: spacing(4);
    @include mobile-only {
        padding: spacing(4);
        padding-bottom: 0;
    }
}

.prizepool {
    display: flex;
    flex-direction: column;
    gap: spacing(4);
    padding: spacing(8);

    @include mobile-only {
        padding: spacing(4);
    }
}

.prize_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.my_rank {
    color: theme(neutral, 50);
}

.my_rank_value {
    color: white;
}