@import "@/styles/base";

.rank_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: spacing(6);
    height: fit-content;

    @include mobile-only {
        gap: spacing(0);
    }
}

.description {
    display: flex;
    flex-direction: column;
    gap: spacing(1);
}

.resource {
    position: relative;
    width: 24px;
    aspect-ratio: 1/1;
}
.image {
    object-fit: contain;
    opacity: 0.8;
}
.balance {
    font-family: var(--font-lexend);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
}
.display_name {
    color: theme(text-color, main);
    font-family: var(--font-lexend);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */

    @include mobile-only {
        font-size: 24px;
        line-height: 125%;
    }
}
.resource_area {
    display: flex;
    align-items: center;
    gap: spacing(2);
}
.current_rank {
    position: relative;
    width: clamp(20vw, 15.625rem, 25vw);
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    isolation: isolate;
    flex-direction: column;
    gap: spacing(1);
    max-width: 15.625rem;
}
.rank_bg {
    object-fit: contain;
    max-width: 100%;
    z-index: -1;
}
.rank_label {
    color: theme(text-color, main);
    text-align: center;
    font-family: var(--font-lexend);
    font-size: clamp(1rem, -0.875rem + 8.333vw, 2.5rem);
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 100% */
}
.rank_percentage {
    color: theme(neutral, 50);
    text-align: center;
    font-family: var(--font-lexend);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
}
