@import "@/styles/base";

.label_container {
    display: flex;
    align-items: center;
    gap: spacing(6);
    max-width: 330px;
}
.progress {
    flex-grow: 1;
}