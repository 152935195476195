@import "@/styles/base";

.single_community_container {
  text-decoration: none;
  color: theme(text-color, main);
}

.card {
  width: 100%;
  display: flex;
  gap: spacing(4);
}

.image {
  border-radius: radius(2);
  overflow: clip;
  aspect-ratio: 1/1;
  object-fit: contain;

  @include mobile-only {
    object-fit: cover;
    aspect-ratio: 1/1;
    width: 48px;
    height: 48px;
  }
}

.community_name {
  color: theme(text-color, main);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */

}

.description_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: spacing(2);
  flex-grow: 1;
  width: 100%;
}

.description_text_wrapper {
  color: theme(neutral, 50);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
}

.card_footer {
  display: flex;
  gap: spacing(4);
  width: 100%;

}

.label {
  color: theme(text-color, main);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}
