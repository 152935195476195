@import "@/styles/base";
.leaderboard_page {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30.625rem, 1fr));
  gap: spacing(6);
  flex-wrap: wrap;
  width: 100%;

  @include mobile-only {
    grid-template-columns: none;
    grid-auto-flow: row;
  }
}

.leaderboard_container {
  width: 100%;
  display: flex; 
  flex-direction:  column;
  gap: spacing(6);
}