@import "@/styles/base";

.event_card {
    display: flex;
    flex-direction: column;
    gap: spacing(4);
    border-radius: radius(3);
    overflow: clip;
    height: 100%;
    background: transparentize(theme(neutral, 100), 0.96);

    @include mobile-only {
        border-radius: radius(2);
    }
}

.cover {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    inset:0;
}
.image_cover {
    position: relative;
    height: 9.6875rem;
    display: flex;;
    flex-direction: column-reverse;
} 

.event_rewards_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: spacing(2) spacing(6);
    justify-content: space-around;

    @include desktop-up {
        justify-content: space-between;
        padding: spacing(2) spacing(8);
    }
}
.participants {
    display: flex;
    align-items: center;
    gap: spacing(1);
    text-decoration: none;
    font-size: 12px;

    @include mobile-only {
        display: none;
    }
}
.event_body {
    display: flex;
    flex-direction: column;
    gap: spacing(4);
    padding: 0 spacing(6) spacing(6);

    @include desktop-up {
        padding: 0 spacing(8) spacing(8);
    }
}
.event_body_header {
    display: flex;
    justify-content: space-between;
}
.event_title {
    grid-area: 2 / 1 / 3 / 3;
    color: theme(text-color, main);
    font-family: var(--font-lexend);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 100% */
}
.event_link {
    text-decoration: none;
    color: theme(text-color, main);
    height: 100%;
}
.event_dates {
    text-align: end;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
}

.event_title_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.score_based {
    display: flex;
    align-items: center;
    gap: spacing(2);
    font-size: 12px;
    font-weight: 500;
}