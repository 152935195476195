@import "@/styles/base";

.inventory_items_loader {
    display: flex;
    gap: spacing(6);
    height: min-content;
    padding-inline: spacing(6);

    @include mobile-only {
        padding-inline: 0;
    }
}

.box {
    width: 100%;
    aspect-ratio: 1/1;
}