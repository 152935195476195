@import "@/styles/base";

.button {
    display: flex;
    width: 100%;
    padding-inline: spacing(2);

    & > button {
        width: 100%;
    }
}