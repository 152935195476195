@import "@/styles/base";

.in_button {
    font-weight: 700;
    line-height: 24px;
    font-size: 1rem;
    padding: spacing(4) spacing(8);
    border-radius: radius(2);
    border: 1px solid theme(status, success);

    @include mobile-only {
        text-align: center;
        width: 100%;
        padding: spacing(3);
    }
}