@import "@/styles/base";

.event_header {
  grid-area: head-content;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 20rem;
  width: 100%;
  border-radius: radius(2);
  overflow: clip;
  padding: spacing(6);

  @include mobile-only {
    height: 14rem;
  }
}
.event_header_bg {
  object-fit: cover;
  object-position: center center;
}